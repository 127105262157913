import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { graphql, Link } from "gatsby";
import { Button, Col, Container, Form, FormControl, Image, Row, Spinner, Tab, Tabs } from "react-bootstrap";
import LoadingOverlay from 'react-loading-overlay';

import "../assets/css/main.scss";
import Layout from "../components/Layout";

import SeedPeopleLeaflet from "./SeedPeopleLeaflet";
import * as leafletImages from '../components/SeedPeopleMaps/LeafletImages';
import SEO from "../components/seo";
import { getIsUserAuthenticated, getToken, isLoggedIn } from "../services/auth";
import { cmsCollections, getAllRecords, getMapData, SearchRecords } from "../services/cmsService";
import { global, loaderStyle, waitingText } from '../constants/common';

const Map = ({data}) => {

    const { seedpeopleTitle, seedpeopleDescription } = data?.seo?.nodes[0];
    const [ isLoading, setIsLoading ] = useState(false);

    const [seedPeopleData, setSeedPeopleData] = useState([]);

    const getSeedPeople = async () => {

      let seedPeopleData = [];

      if (getIsUserAuthenticated()) {
          setIsLoading(true);
          try {
            const token = getToken();
            const ps = -1;
            const pn = 1;
            const filters = { "type":"", "state":"", "interest":"", "sort":"firstname" };
            //seedPeopleData = await getAllRecords(token, cmsCollections.Userprofiles).then((res: any) => res ? res.data : []);
            //seedPeopleData = await SearchRecords('', cmsCollections.Userprofiles, " ", ps, pn, filters).then((res: any) => res ? res.data : []);
            seedPeopleData = await getMapData(token).then((res: any) => res ? res.data : []);
            console.log("Got UserProfiles as:", seedPeopleData);

            setSeedPeopleData(seedPeopleData);
          }
          catch (e: any) {
              console.log(e.description);
          }
          finally {
              setIsLoading(false);
          }
      }

      return data;
    };

    useEffect(() => {
        const fn = async () => {
            await getSeedPeople();
        }
        if(!seedPeopleData || seedPeopleData.length == 0) {
          fn();
        }
    },[]);

    return (
      <Layout>
          <SEO title={seedpeopleTitle} description={seedpeopleDescription}></SEO>
          <Container className="map mb-3">
            <LoadingOverlay active={isLoading} spinner text="Please wait while we load map data..." styles={loaderStyle}>
              <div className="sp-header">
                  <h4>Seed People Location</h4>
                  <Link className="btn btn-outline-secondary btn-md" role="button" to="/seed-people-directory">
                    <FontAwesomeIcon icon='filter' className="fa" />&nbsp;&nbsp;Directory View
                  </Link>
              </div>
              <div> 
                <strong>Legend</strong>: 
                <img src={leafletImages.redMarker} width="15" />-Seed&nbsp;Suppliers, 
                <img src={leafletImages.violetMarker} width="15"/>-Seed&nbsp;Production&nbsp;Areas, 
                <img src={leafletImages.blueMarker}  width="15"/>-Nurseries, 
                <img src={leafletImages.greenMarker}  width="15"/>-Seed&nbsp;Collectors
              </div>
              <div>Want to search by capability or seed person name instead? Click on 'Directory View'</div>
              <div id="mapView">
                <SeedPeopleLeaflet data={seedPeopleData} />
              </div>
            </LoadingOverlay>
          </Container>
      </Layout>
    )
}

export const allUserProfilesQry = graphql `
query MyQuery {
    seo: allStrapiSeo {
      nodes {
        seedpeopleDescription
        seedpeopleTitle
      }
    },
  }
`;

export default Map;

// export const allUserProfilesQry = graphql `
// query MyQuery {
//     allStrapiUserprofile(filter: {isUserVisibile: {ne: false}}) {
//       nodes {
//         id
//         firstname
//         lastname
//         organisation
//         suburb
//         state
//         postcode
//         lat
//         long
//         isUserVisibile
//         isNursery
//         isSeedBank
//         isSeedCollector
//         isSeedProductionArea
//         userPhoto {
//           id
//           absolutePath
//           childImageSharp {
//             fluid {
//               src
//             }
//           }
//         }
//       }
//     },
//     seo: allStrapiSeo {
//       nodes {
//         seedpeopleDescription
//         seedpeopleTitle
//       }
//     },
//   }
//   `;


